import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const RemortgagingPage = () => (
    <Layout>
        <Seo title={'What to do if you want to remortgage'} description={'Thinking about remortgaging whilst you have a secured loan from Nemo? For important information on what you have to tell us and how we can help, click here.'} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/customer-service/moving-home">How your Nemo mortgage
                            works</a></li>
                        <li><a href="/customer-service/remortgaging" className={'selected'}>What to do if you want to
                            remortgage </a></li>
                        <li><a href="/customer-service/settle-my-second-charge-mortgage">Settle
                            my Nemo mortgage early</a></li>
                        <li><a href="/customer-service/update-my-details">Update my details</a>
                        </li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>What to do if you want to remortgage </h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>You will need to contact us and apply for a Deed of Postponement if you want to
                            re-mortgage.&nbsp;This page tells you everything you need to know about this process.&nbsp; </p>
                        <p>
                            <strong>What is a Deed of Postponement?&nbsp;</strong></p>
                        <p>If you want to remortgage and have both a mortgage and a (Nemo) second charge mortgage on the
                            property, your new mortgage lender will insist on you obtaining a Deed of Postponement from
                            Nemo. This is because your mortgage lender wish to have first legal charge (or claim) on your
                            property in the event you can't repay your debts, and your house has to be repossessed.
                            Financial charges or claims normally take precedence in order of the date they are registered,
                            the second charge mortgage provider (Nemo) will need to “postpone” their claim until after the
                            new mortgage lender has made theirs i.e. Deed of Postponement.<br /><br /><strong>How do I apply for
                                a Deed of Postponement? </strong></p>
                        <p>We will need the following documents to be able to consider your application, and once all the
                            information is received, the process should take us five days. </p>
                        <ul>
                            <li>A copy of your new Mortgage Offer showing the new first charge mortgage amount, term and
                                monthly
                                repayment.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </li>
                            <li>A copy of your latest property valuation, if this is not indicated on your Mortgage
                                Offer.&nbsp;&nbsp; </li>
                            <li>Copies of your latest three months’
                                payslips.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </li>
                            <li>Your signed consent allowing us to communicate with your solicitor and/or the new first
                                charge mortgage lender.&nbsp; </li>
                            <li>If you’re borrowing more money as part of your re-mortgage, please tell us what this will be
                                used for.&nbsp; If you’re not, please send us a redemption statement for your current first
                                charge mortgage.
                            </li>
                        </ul>
                        <p>Details of your new first charge mortgage and a current valuation of your property will be used
                            to calculate whether the remaining equity in your home satisfies our criteria and to ensure that
                            it is in your best interests.</p>
                        <p>If your application is approved this service will cost £125.00 for administration.&nbsp; You can
                            send a cheque payable to Nemo Personal Finance Limited, or you can pay over the phone.&nbsp; If
                            your secured property is in Scotland you will need to pay additional costs due to Scottish
                            legislation which can be found in our <a href="/our-fees">Tariff of Fees and Charges</a>.
                        </p>
                        <p>Self-employed customers should also send: </p>
                        <ul>
                            <li>Proof of Income: an accountant's letter / reference OR the Inland Revenue response form,
                                SA302, from your Self-Employed Self-Assessment submission. Please note that an accountants’
                                reference is only acceptable from a practising accountant with a recognised qualification.
                            </li>
                            <li>Proof of Trading:&nbsp; either in the form of an accountants’ letter or copies of an invoice
                                dated within the last three months.
                            </li>
                        </ul>
                        <p>Please send this information by post or fax, details can be found <a
                            href="/contact-us">here</a>.&nbsp;<br /><br />Potential outcomes of your application:</p>
                        <ol>
                            <li>Your application is approved</li>
                            <li>Your application is approved subject to a reduction to your Nemo outstanding balance so that
                                your Nemo borrowing fits our criteria. See our&nbsp;<a
                                    href="/faq">FAQs</a> section
                                about overpayments to understand the impact of this sort of payment.&nbsp; </li>
                            <li>Your application is declined, halting the progress of your re-mortgage.&nbsp; If this
                                happens you may consider borrowing further funds from the new lender to settle your Nemo
                                second charge mortgage, or alternatively you reduce the amount of your new first charge
                                mortgage. A no-obligation settlement quotation will be sent to you if we decline your
                                application.&nbsp; You can appeal this decision by contacting us, and by evidencing
                                financial viability and affordability of the re-mortgage.&nbsp; </li>
                        </ol>
                        <p>
                            <strong>What do I do if I can’t get all of the information required for a Deed of
                                Postponement?&nbsp; </strong>
                        </p>
                        <p>
                            <strong>If you have yet to receive a full mortgage offer:</strong> Ask your new lender to send
                            us a letter confirming the new term, the total amount being borrowed, the new monthly payment
                            and current property valuation (including date carried out).</p>
                        <p>
                            <strong>If you do not have an update to date valuation from your new lender:</strong> You’ll
                            need to get a valuation survey carried out by a FISA or RICS surveyor; your new lender would
                            need to include the outcome of this survey in their mortgage offer.&nbsp; We cannot accept any
                            alternatives.&nbsp;&nbsp; </p>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="fbCol">
                    <div className="fbList">
                        <ul>
                            <li className="ducks">
                                <div className="top"></div>
                                <div className="txtWrap">
                                    <h3>FAQs</h3>
                                    <p>Can't find the information you're looking for? <a
                                        href="/faq">View
                                        our FAQs</a></p>
                                </div>
                            </li>
    
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
)

export default RemortgagingPage;